import React from 'react'
import { ReactComponent as JetonSVG } from '../../img/Jeton.svg'

function resolveJetonClass(value) {
  switch(Number(value)) {
    case 1: return 'b1-color--green';
    case 2: return 'b1-color--blue';
    case 3: return 'b1-color--red';
    case 4: return 'b1-color--gold';
    default: return 'b1-color--default';
  }
}

function Jeton({ value }) {
  console.log('jeton', value)
  const cls = resolveJetonClass(value)
  console.log('class', cls)

  return (
    <div className={cls} style={{display: 'inline-block', minWidth: '1em'}}>
      <JetonSVG/>
    </div>
  )
}

export default Jeton
