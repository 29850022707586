import React from 'react'
import { Jeton } from './Jeton'
//import { value2jeton } from '../functions/Conversions'

function StakeIcon({value}) {
  return (
    <Jeton value={value}/>
  )
/*
  const num = value2jeton(value)
  return (
    <img src={'/img/jeton-'+num+'.svg'} alt={`Jeton Wert ${value}`} style={{height: '1em'}} className="inline-image"/>
  )
*/
}

export default StakeIcon
