import React, { useState } from 'react'
//import { useParams } from 'react-router-dom'
import { FinishTurnButton, Progress } from '../../components'
import QuestionCard from './QuestionCard'
import AnswerCard from './AnswerCard'
import EmptyCard from './EmptyCard'

function checkResult(res, n) {
  const values = res.slice(0, n).map(item => item.text)
  //console.log('values', values)
  const complete = values.find(el => el === '') === undefined
  const text = values.join(' ')
  //console.log('complete', complete)
  return [complete, text]
}

function myDrop(ev, state, setState) {
  ev.preventDefault()
  const data = ev.dataTransfer.getData('text')
  const src = document.getElementById(data)
  const srcSlot = src.getAttribute('slot')
  const dst = ev.currentTarget
  const dstSlot = dst.getAttribute('slot')
  // AnswerCard(idx) als PlacedCard an Slot slot ablegen und aus QuellListe löschen
  const srcItem = state.slots[srcSlot]
  const dstItem = state.slots[dstSlot]
  const slots = [].concat(state.slots)
  slots[srcSlot] = dstItem
  slots[dstSlot] = srcItem
  const answer = checkResult(slots, state.slotsNumber)
  console.log('answer', answer)
  setState({...state, slots: slots, answer: answer})
}

function getSlotsNumber(set) {
  return set.question.split('/').length
}

function initSlots(set, slots) {
  // slots is the number of elements in the upper row (question and empty)
  let content = []
  const quests = set.question.split('/').map(str => str.trim())
  //console.log('quests', quests)
  for(let i=0; i<quests.length; i++) {
    if(quests[i].length) {
      content.push({type: 'question', slot: i, text: quests[i]})
    } else {
      content.push({type: 'empty', slot: i, text: ''})
    }
  }
  const answers = set.answers.map((item, idx) => ({type: 'answer', slot: idx, text: item.text}))
  return content.concat(answers)
}

function Poker({task, state, onFinish, progress}) {  // task, state[setId, set, mode], setStatus
  //const [state, setState] = useContext(AppContext)
  //const setId = props.state.setId
  
  console.log('poker state', state)
  
  const set = state.set
  const [myState, setMyState] = useState({
    slotsNumber: getSlotsNumber(set),
    slots: initSlots(set),
    answer: [false, '']
  })
  const drag = ev => ev.dataTransfer.setData("text", ev.target.id)
  const drop = ev => myDrop(ev, myState, setMyState)
  const mapCards = (items, offset) => {
    return items.map((item, index) => {
      const idx = index + offset
      switch(item.type) {
        case 'question': return (<div key={idx} className="b1-flex__container-item b1-flex__container-item--margin b1-flex__container-item--min-width-150"><QuestionCard slot={idx} text={item.text}/></div>)
        case 'empty': return (<div key={idx} className="b1-flex__container-item b1-flex__container-item--margin b1-flex__container-item--min-width-150"><EmptyCard slot={idx} onDrop={drop}/></div>)
        case 'answer': return (<div key={idx} className="b1-flex__container-item b1-flex__container-item--margin b1-flex__container-item--min-width-150"><AnswerCard idx={idx} slot={idx} text={item.text} onDrag={drag}/></div>)
        default: return (<p key={idx}>Da hat was nicht so ganz geklappt...</p>)
      }
    })
  }
  const solution = 'bla'
  return (
    <div id="screen-poker">
      <Progress width={progress}/>
      <p>{solution}</p>
      <div className="b1-flex__container rounded-top b1-poker__table-top">
        { mapCards(myState.slots.slice(0, myState.slotsNumber), 0) }
      </div>
      <div className="b1-flex__container rounded-bottom b1-poker__table-bottom">
        { mapCards(myState.slots.slice(myState.slotsNumber), myState.slotsNumber) }
      </div>
  
      <FinishTurnButton complete={myState.answer[0]} answer={myState.answer[1]} solutions={set.solutions} task={task} onFinish={onFinish}></FinishTurnButton>
    </div>
  )
}

export default Poker
