import React from 'react'
import 'whatwg-fetch'
import {
  BrowserRouter as Router,
  Route, Switch
} from 'react-router-dom'

import {
  Home, Login, Dashboard, Error,
  Task, Tasks,
  Player, Stake
} from '../views'

function App() {
/*
  return (
    <LevelsProvider>
      <TasksProvider>
        <PlayerProvider>
          <Router>
            <Frame>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/player" component={Player} />
                <Route path="/tasks" component={Tasks} />
                <Route path="/task/:id" component={Task} />
                <Route path="/roulette/:id" component={Roulette} />
                <Route path="/poker/:id" component={Poker} />
                <Route path="/slots/:id" component={Slots} />
                <Route path="/stake" component={Stake} />
                <Route component={Error} />
              </Switch>
            </Frame>
          </Router>
        </PlayerProvider>
      </TasksProvider>
    </LevelsProvider>
  )
*/
  return (
    <Router>
      <Switch>
        <Route path="/" exact><Home/></Route>
        <Route path="/login"><Login/></Route>
        <Route path="/dashboard"><Dashboard/></Route>
        <Route path="/player"><Player/></Route>
        <Route path="/tasks"><Tasks/></Route>
        <Route path="/task/:id"><Task/></Route>
        <Route path="/stake"><Stake/></Route>
        <Route><Error/></Route>
      </Switch>
    </Router>
  )
}

export default App
