import React from 'react'

const AnswerCard = props => (
  <div id={'card'+props.idx} idx={props.idx} slot={props.slot} text={props.text} className="b1-poker__card b1-poker__card--color b1-poker__card--shadow" draggable="true" onDragStart={props.onDrag}>
    <div className="b1-poker__card__inner b1-poker__card--border-color-light b1-poker__card--watermark">
      <span className="b1-poker__card__text">
        {props.text}
      </span>
    </div>
  </div>
)

export default AnswerCard
