import types from './types';
import { getStore, setStore } from '../functions/Storage'

const initialState = getStore('tasks', [
  [], [], [], [], []  // one list of task for each level
])

const reducer = (state = initialState, action) => {
  // Fehler direkt ignorieren
  if(action.payload && action.payload.error) return state
  // sonst Behandlung der Action
  switch(action.type) {
    case types.FETCH_TASKS:
      const key = action.key ? action.key : 0
      const clone = [...state]
      clone[key] = action.payload
      setStore('tasks', clone)
      return clone
    default:
      return state
  }
}

const fetchTasks = level => ({
  type: types.FETCH_TASKS,
  payload: '',
  key: level,
  meta: {
    type: 'api',
    url: '/tasks/default/'+level,
    method: 'GET'
  }
});

const tasks = {
  initialState,
  reducer,
  fetchTasks
}

export default tasks
