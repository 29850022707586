import React from 'react';
import { Redirect } from 'react-router-dom';
import Frame from '../containers/Frame'

const Home = props => {
  return (
    <Frame>
      <div id="screen-home">
        <p>Homescreen</p>
        <Redirect to="/dashboard" />
      </div>
    </Frame>
  )
}

export default Home;
