import React from 'react'
import { value2jeton } from '../functions/Conversions'

function StakeJeton({value, bank}) {
  const num = value2jeton(value)
  if(bank < value) return (<p></p>)
  return (
    <img src={'/img/jeton-'+num+'.svg'} alt={`Jeton Wert ${value}`} />
  )
}

export default StakeJeton
