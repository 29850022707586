import React from 'react'

const Overlay = props => {    // viewBox, textList

  const textX = -55
  const textY = 3
  const list = ['???'].concat(props.textList)
  
  const listItems = list.map((item, idx) =>
    <g key={idx} transform={'rotate('+idx*45+')'}>
      <text x={textX} y={textY} className="b1-roulette--small">{item}</text>
    </g>    
  )

  return (
    <svg className="b1-roulette__wheel__svg" viewBox={props.viewBox}>
      <g transform="translate(65, 65)">
        {listItems}
      </g>
    </svg>
  )
}

export default Overlay
