import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import Frame from '../../containers/Frame'
import Poker from '../Poker'
import Slots from '../Slots'
import Roulette from '../Roulette'
import { calcTaskProgress } from '../../functions/Progress'

function mapStateToProps(state) {
  //console.log('map routine state', state.routines);
  return {
    player: state.players,
    tasks: state.tasks
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //fetchLevels: () => dispatch(levels.fetchLevels()),
    //setLevel: level => dispatch(players.setLevel(level))
  }
}

function selectRandomItem(list) {
  const id = Math.floor(Math.random() * list.length)
  const item = list[id]
  return {id, item}
}

function initState() {
  return {
    setId: undefined,
    set: undefined,
    mode: '', // roll, turn, win, lose
  }
}

function Task(props) {
  const { id } = useParams()
  const task = props.tasks[props.player.level].find(elem => elem.id === id)
  const [myState, setMyState] = useState(initState())

  const progress = calcTaskProgress(task, props.player.tasks)
  
  const onFinish = hit => {
    if(hit) {
      console.log('onFinish', hit)
      setMyState(initState())
    }
  }
  
  useEffect(() => {
    if(!myState.set) {
      const item = selectRandomItem(task.questions)
      setMyState({
        setId: item.id,
        set: item.item,
        mode: ''
      })
    }
  })
  
  if(!myState.set) return (
    <Frame><p>gleich geht es weiter...</p></Frame>
  )
  
  switch(task.type) {
    case 'poker': return (<Frame><Poker task={task} state={myState} onFinish={onFinish} progress={progress}/></Frame>)
    case 'slots': return (<Frame><Slots task={task} state={myState} onFinish={onFinish} progress={progress}/></Frame>)
    case 'roulette': return (<Frame><Roulette task={task} state={myState} onFinish={onFinish} progress={progress}/></Frame>)
    default: return (<Frame><div>Hier ist leider etwas schief gelaufen. Bitte versuche es noch einmal.</div></Frame>)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Task)
