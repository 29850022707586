import React, { useState } from 'react'
//import { useParams } from 'react-router-dom'
import Overlay from './Overlay'

import { ReactComponent as RouletteSVG } from '../../img/Roulette_130.svg';

let oldPos = undefined

const getClientY = e => e.touches ? e.touches[0].clientY : e.clientY

function Wheel(props) {    // textList
  const [myState, setMyState] = useState({rotation: 0})
  //const { id } = useParams()
  let dragging = false
  let rotation = myState.rotation
  
  const getAnswerFromAngle = rot => {
    const module = -(rot % 360)
    const section = Math.round(module/45) - 1
    if(props.textList[section]) return props.textList[section].text
    return ''
  }
  
  const handleUp = e => {
    dragging = false
    const body = document.querySelector('body')
    body.classList.remove('noScroll')
    setMyState({...myState, rotation: rotation})
    const ans = getAnswerFromAngle(rotation)
    if(props.onRotation) props.onRotation(ans)
  }
  
  const handleDown = e => {
    dragging = true
    oldPos = getClientY(e)
    if(!e.touches) e.preventDefault()
    const body = document.querySelector('body')
    body.classList.add('noScroll')
  }
  
  const handleMove = e => {
    if(!dragging) return
    if(!e.touches) e.preventDefault()
    const curPos = getClientY(e)
    const halfDiff = (curPos - oldPos) / 2
    rotation -= halfDiff
    oldPos = curPos
    e.currentTarget.style.transform=`rotate(${rotation}deg)`
  }

  // ViewBoxen der beiden SVGs müssen übereinstimmen!!!
  return (
    <div id="wheel" className="b1-roulette__wheel b1-roulette__spinner"
      onMouseDown={handleDown} onMouseUp={handleUp} onMouseMove={handleMove}
      onTouchStart={handleDown} onTouchEnd={handleUp} onTouchMove={handleMove}
    >
      <RouletteSVG className="b1-roulette__wheel__svg" alt="roulette wheel"/>
      <Overlay viewBox="0 0 130 130" textList={props.textList.map(item => item.text)}/>
    </div>
  )
}

export default Wheel
