import { createStore, applyMiddleware } from 'redux';
import { rootReducer, initialState } from './reducers';
//import loggingMiddleware from './loggingMiddleware';
import apiMiddleware from './apiMiddleware';

const configureStore = () => {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      apiMiddleware,
//      loggingMiddleware,   // call at last to log changes made by other middleware
    )
  );
  return store;
}

export default configureStore;
