import { apiBaseURL } from '../../config'
import { getStore } from '../../functions/Storage'


function api(store, next, action) {
  // find request url and compose fetch options
//  const {token} = store.getState().accessToken
  const url = apiBaseURL + action.meta.url
  const body = action.payload ? JSON.stringify(action.payload) : undefined

//  console.log('middleware url', url)
  const token = getStore('access_token', {jwt:'', exp:0})
  const headers = {
    'Content-Type': 'application/json',
    'Access-Token': token.jwt
  }
//  const headers = Object.assign({},
//    {'Content-Type': 'application/json'},
//    token ? {'X-XSRF-TOKEN': token} : {}
//    token ? {'Authorization': 'Bearer '+token} : {}
//  )
  const options = {...action.meta, headers, body}

  //console.log('redux api', action);
  //console.log('options', options);

  console.log('api middleware fetch 1', url, options)
  fetch(url, options)
  .then(res => res.json())
  // respond back to the user by dispatching the original action without the meta object
  .then(json => {
    console.log('api middleware fetch 2', json)
    const newAction = {...action, payload: json}
//    let newAction = Object.assign({}, action, {payload: json})
    delete newAction.meta
    store.dispatch(newAction)
  })
}

const apiMiddleware = store => next => action => {
  if(!action.meta) return next(action)
  switch(action.meta.type) {
    case 'api': return api(store, next, action)
    default: return next(action)
  }
}

export default apiMiddleware
