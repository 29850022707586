import React from 'react'
import { Link } from 'react-router-dom'
import { Progress } from '../../components'
import GameIcon from '../../components/GameIcon'

function Card({id, type, name, progress}) {
  //console.log('Item props', props)
  const myTarget = `/task/${id}`
  return (  
    <Link to={myTarget} className="b1-task__list-item b1-card--shadow">
      <p className="valign-wrapper">
        <GameIcon type={type}/> &nbsp;
        <span className="title">{name}</span>
      </p>
      <Progress width={progress}/>
    </Link>
  )
}

export default Card
