const value2jeton = val => {
  switch(val) {
    case 1: return 1
    case 2: return 2
    case 5: return 3
    case 10: return 4
    case 20: return 5
    default: return 1
  }
}

const jeton2value = val => {
  switch(val) {
    case 1: return 1
    case 2: return 2
    case 3: return 5
    case 4: return 10
    case 5: return 20
    default: return 1
  }
}

export {
  value2jeton,
  jeton2value
}