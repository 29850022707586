import React from 'react'
import Card from './Card'
import { calcTaskProgress } from '../../functions/Progress'

function TaskList({tasks, player}) {
  //console.log('TaskList tasks', tasks)
  if(!tasks) return (<div className="b1-task__list"></div>)
                     
  const listItems = tasks.map(item => {
    //console.log('listItems', item.id)
    const progress = calcTaskProgress(item, player)
    return (
      <Card key={item.id} id={item.id} name={item.name} type={item.type} progress={progress}/>
    )
  })
  return (  
    <div className="b1-task__list">
      {listItems}
    </div>
  )
}

export default TaskList
