function calcTaskProgress(task, playerStateTasks) {
  const taskWin = playerStateTasks[task.id] ? Number(playerStateTasks[task.id].win) : 0
  const taskMax = Number(task.max) ? Number(task.max) : task.questions.length
  //console.log('win max', taskWin, taskMax)
  if(taskMax) return taskWin / taskMax * 100 + '%'
  if(taskWin) return '100%'
  return '0%'
}

export {
  calcTaskProgress
}
