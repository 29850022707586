const FETCH_ACCESS_TOKEN = 'FETCH_ACCESS_TOKEN'
const DEACTIVATE_ACCESS_TOKEN = 'DEACTIVATE_ACCESS_TOKEN'
const FETCH_CSRF_TOKEN = 'FETCH_CSRF_TOKEN'
const FETCH_LEVELS = 'FETCH_LEVELS'
const FETCH_TASKS = 'FETCH_TASKS'
const FETCH_PLAYER = 'FETCH_PLAYER'
const SET_PLAYER_LEVEL = 'SET_PLAYER_LEVEL'
const UPDATE_PLAYER = 'UPDATE_PLAYER'

const types = {
  FETCH_ACCESS_TOKEN,
  DEACTIVATE_ACCESS_TOKEN,
  FETCH_CSRF_TOKEN,
  FETCH_LEVELS,
  FETCH_TASKS,
  FETCH_PLAYER,
  SET_PLAYER_LEVEL,
  UPDATE_PLAYER
}

export default types
