import React from 'react'
import Frame from '../containers/Frame'

function Player(props) {
  return (
    <Frame>
      <div id="screen-player">
        <p>Player</p>
      </div>
    </Frame>
  )
}

export default Player
