import React from 'react'
import { Link } from 'react-router-dom'
import LevelIcon from './LevelIcon'
import StakeIcon from './StakeIcon'

function SideMenu({ state, title }) {

  console.log('side state', state)
  const currentLevel = {level: state.level, stake: state.stake, ...state.levels[state.level]}
  
  return (
    <div>
      <input type="checkbox" id="sidenav-toggle" className="b1-menu__checkbox"/>

      <ul id="slide-out" className="b1-menu__sidenav b1-color--bg-default">
        <li><LevelIcon level={currentLevel.level} locked={false}/></li>
        <li><a href="#!">Bank: {currentLevel.bank}</a></li>
        <li><a href="#!">Stake: {currentLevel.stake} <StakeIcon value={currentLevel.stake} /></a></li>
        <li><div className="divider"></div></li>
        <li><Link to="/dashboard">Dashboard</Link></li>
        <li><Link to="/tasks">Aufgaben</Link></li>
        <li><Link to="/login">Login</Link></li>
        <li><hr/></li>
        <li><a href="#!">Datenschutzerklärung</a></li>
        <li><a href="#!">Impressum</a></li>
        <li><a href="#!">Credits</a></li>
        <li><a href="#!">Kontakt</a></li>
      </ul>

      <div className="b1-flex__container b1-flex__container--centered-v b1-flex__container--left">
        <label htmlFor="sidenav-toggle" style={{display: 'inline-block', width: "5em"}}>
          <LevelIcon level={currentLevel.level} locked={false}/>
        </label>
        <strong>
          <span style={{marginLeft: '1em'}}>Bank: {currentLevel.bank}</span>
          <span className="b1-flex__inline--centered-v" style={{marginLeft: '1em'}}> &nbsp; Stake: {currentLevel.stake} <StakeIcon value={currentLevel.stake}/></span>
          <span style={{marginLeft: '1em'}}>{title ? title : 'B1 royal'}</span>
        </strong>
      </div>
    
      <label htmlFor="sidenav-toggle" className="b1-menu__sidenav-overlay"></label>
    </div>
  )
}

export default SideMenu
