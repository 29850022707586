import types from './types'

const initialState = [
  {
    bank: 0
  },
  {
    bank: 0
  },
  {
    bank: 0
  },
  {
    bank: 0
  },
  {
    bank: 0
  }
]

const reducer = (state = initialState, action) => {
  // Fehler direkt ignorieren
  if(action.payload && action.payload.error) return state
  // sonst Behandlung der Action
  switch(action.type) {
    case types.FETCH_LEVELS:
      console.log('fetch levels reducer', state, action.payload)
      const clone = [...state]
      for(const item of action.payload) {
        clone[item.level].bank = item.required
      }
      return clone
    default:
      return state
  }
}

const fetchLevels = () => ({
  type: types.FETCH_LEVELS,
  payload: '',
  meta: {
    type: 'api',
    url: '/levels/default',
    method: 'GET',
  }
})

const levels = {
  initialState,
  reducer,
  fetchLevels
}

export default levels
