function setStore(name, data) {
  localStorage.setItem(name, JSON.stringify(data))
}

function getStore(name, init) {
  let storage = JSON.parse(localStorage.getItem(name))
  if(!storage && init !== undefined) storage = init
  return storage
}
/*
function setLocal(name, data) {
  localStorage.setItem(name, JSON.stringify(data))
}

function getLocal(name, init) {
  let storage = JSON.parse(localStorage.getItem(name))
  if(!storage && init !== undefined) storage = init
  return storage
}

function setSession(name, data) {
  sessionStorage.setItem(name, JSON.stringify(data))
}

function getSession(name, init) {
  let storage = JSON.parse(sessionStorage.getItem(name))
  if(!storage && init !== undefined) storage = init
  return storage
}
*/

export {
  getStore,
  setStore
}
