import React from 'react'
import { Link } from 'react-router-dom'
import LevelIcon from '../../components/LevelIcon'

function locked(props) {
  return (
    <div className="b1-card">
      <div className="b1-card__image">
        <LevelIcon level={props.level} locked={true} />
      </div>
      <div className="b1-card__content">
        <p>Erforderlicher Punktestand: { props.bank }</p>
      </div>
    </div>
  )
}

function unlocked(props) {
  return (
    <Link to="/tasks" onClick={e => {props.clicked(props.level)}}>
      <div className="b1-card">
        <div className="b1-card__image">
          <LevelIcon level={props.level} locked={false} />
        </div>
        <div className="b1-card__content">
          <p>Bank: { props.bank }</p>
        </div>
      </div>
    </Link>
  )
}

const Card = (props) => {
  console.log('Card props', props)
  return props.locked ? locked(props) : unlocked(props)
}

export default Card
