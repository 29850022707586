import { useState, useEffect } from 'react';

// liefert flag, das nur beim ersten mal TRUE ist

export default function useFirstTime() {
  const [state, setState] = useState(true)
  
  useEffect(() => {
    setState(false)
  }, [])

  return state
}
