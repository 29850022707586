import React from 'react'

const imgTag = type => {
  switch(type) {
    case 'poker':
      return (<img src={'/ico/icons8-cards-50.png'} alt="Poker"/>)
    case 'roulette':
      return (<img src={'/ico/icons8-roulette-50.png'} alt="Roulette"/>)
    case 'slots':
      return (<img src={'/ico/icons8-slot-machine-50.png'} alt="Slot Machine"/>)
    default:
      return (<span>unbekannter Typ: {type}</span>)
  }
}

const GameIcon = (props) => (
  <span className="valign-wrapper">
    {imgTag(props.type)}
  </span>
)

export default GameIcon
