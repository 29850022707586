import React from 'react'
import { Link } from 'react-router-dom'
import Frame from '../containers/Frame'

function Error(props) {
  return (
    <Frame>
      <div className="row">
        <div className="col s12 m8 offset-m2 l4 offset-l4">
          <p>Hier ist etwas schief gelaufen!</p>
          <p>Die angeforderte Seite existiert nicht!</p>
          <p><Link to="/">zurück zum Start</Link></p>
          <p><Link to="/dashboard">zurück zum Dashboard</Link></p>
        </div>
      </div>
    </Frame>
  )
}

export default Error
