import React, { useState } from 'react'
//import { useParams } from "react-router-dom"
import { FinishTurnButton, Progress } from '../../components'
import Wheel from './Wheel'

function Roulette({task, state, onFinish, progress}) {  // task, state[setId, set, mode], setStatus
  const [myState, setMyState] = useState({complete: false, answer: ''})
  //const { id } = useParams()
  const set = state.set

  const onRotation = text => {
    console.log('on rotate', text)
    setMyState({...myState, complete: !!text, answer: text})
  }
  
  console.log('answers', set.answers)
  return (
    <div className="b1-roulette">
      <Progress width={progress} className="b1-meter--attached"/>
      <div className="b1-roulette__table flex-container valign-wrapper rounded-bottom disp-on-l">
        <div style={{flexGrow: '1'}}>&nbsp;</div>
  
        <div className="b1-poker__card b1-poker__card--color b1-poker__card--shadow" style={{flexGrow: '1'}}>
          <div className="b1-poker__card__inner b1-poker__card--border-color b1-poker__card--watermark">
            <span className="b1-poker__card__text">{set.question}</span>
          </div>
        </div>

        <div style={{flexGrow: '1'}}>&nbsp;</div>
        <div><i className="material-icons yellow-text text-darken-1" style={{fontSize: '5em'}}>play_arrow</i></div>

        <div className="right-align b1-roulette__wheel-box">
          <Wheel textList={set.answers} onRotation={onRotation}/>
        </div>
        <FinishTurnButton className="b1-roulette__button" complete={myState.complete} answer={myState.answer} solutions={set.solutions} task={task} onFinish={onFinish}></FinishTurnButton>
      </div>
    </div>
  )
}

export default Roulette
