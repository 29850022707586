import React from 'react'
import { ReactComponent as CrownSVG } from '../../img/Crown.svg'
import { ReactComponent as CrownA1 } from '../../img/CrownA1.svg'
import { ReactComponent as CrownA2 } from '../../img/CrownA2.svg'
import { ReactComponent as CrownB1 } from '../../img/CrownB1.svg'
import { ReactComponent as CrownB2 } from '../../img/CrownB2.svg'

function resolveClass(level, locked) {
  if(locked) return 'b1-color--default';
  switch(Number(level)) {
    case 0: return 'b1-color--default';
    case 1: return 'b1-color--green';
    case 2: return 'b1-color--blue';
    case 3: return 'b1-color--red';
    case 4: return 'b1-color--gold';
    default: return 'b1-color--default';
  }
}

function resolveText(level) {
  const txts = [null, CrownA1, CrownA2, CrownB1, CrownB2]
  return txts[level]
}

function Crown({ level, locked }) {
  console.log('crown', level, locked)
  const cls = resolveClass(level, locked)
  const txt = resolveText(level)
  console.log('class text', cls, txt)

  return (
    <div className={'b1-crown ' + cls}>
      <CrownSVG className="relative"/>
      { txt ? txt.render({}) : '' }
    </div>
  )
}

export default Crown
