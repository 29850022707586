import React from 'react'

const QuestionCard = props => {
  return (
    <div slot={props.slot} className="b1-poker__card b1-poker__card--color b1-poker__card--shadow">
      <div className="b1-poker__card__inner b1-poker__card--border-color b1-poker__card--watermark" style={{borderColor: props.color}}>
        <span className="b1-poker__card__text">{props.text}</span>
      </div>
    </div>
  )
}

export default QuestionCard
