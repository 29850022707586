import React, { useContext } from 'react'
import { useParams } from "react-router-dom"
//import { AppContext } from '../../contexts/AppContext'
import Slot from './Slot'

const Slots = props => {
//  const [state, setState] = useContext(AppContext)
  const { id } = useParams()
  const task = props.task
  const set = props.state.set

  return (
    <div id="screen-slots">
      <div className="b1-flex__container rounded pink darken-3">
        <Slot/>
        <Slot/>
        <Slot/>
      </div>
    </div>
  )
}

export default Slots
