import React from 'react'

const Slot = (props) => {
  return (
    <div className="b1-slots__container">
      <div className="b1-slots__content">
        <p className="b1-slots__item">erste Zeile</p>
        <p className="b1-slots__item">zweite Zeile</p>
        <p className="b1-slots__item">dritte Zeile</p>
        <p className="b1-slots__item">vierte Zeile</p>
        <p className="b1-slots__item">fünfte Zeile</p>
        <p className="b1-slots__item">sechste Zeile</p>
      </div>
      <div className="b1-slots__shadow"></div>
    </div>
  )
}

export default Slot;
