import { combineReducers } from 'redux'

//import { reducer as csrfTokenReducer, initialState as csrfTokenInitialState } from '../csrfToken'
//import * as accessToken from '../accessToken'
import levels from '../levels'
import tasks from '../tasks'
import players from '../players'

const rootReducer = combineReducers({
//  csrfToken: csrfToken.reducer,
//  accessToken: accessToken.reducer,
  levels: levels.reducer,
  tasks: tasks.reducer,
  players: players.reducer,
})

const initialState = {
//  csrfToken: csrfToken.initialState,
//  accessToken: accessToken.initialState,
  levels: levels.initialState,
  tasks: tasks.initialState,
  players: players.initialState,
}

export default rootReducer
export {
  rootReducer,
  initialState
}
