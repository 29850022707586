import React from 'react'
import Crown from './Crown'

const LevelIcon = props => {
  return (
    <Crown level={props.level} locked={props.locked}/>
  )
}

export default LevelIcon
