import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import { apiBaseURL } from '../config'
import { setStore } from '../functions/Storage'
import Frame from '../containers/Frame'

function Login(props) {
  const history = useHistory()
  const [loginState, setLoginState] = useState({
    user: '',
    pass: ''
  })
  
  const onUserChange = ev => {
    setLoginState({...loginState, user: ev.target.value})
  }
        
  const onPassChange = ev => {
    setLoginState({...loginState, pass: ev.target.value})
  }

  const onButtonClick = ev => {
    ev.preventDefault()
    fetch(apiBaseURL + '/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        usr: loginState.user,
        pwd: loginState.pass
      })
    })
    .then(res => res.json())
    // respond back to the user by dispatching the original action without the meta object
    .then(json => {
      if(!json.ok) return
      setStore('access_token', json)
      history.push('/')
    })
  }
  
  return (
    <Frame>
      <div id="screen-login">
        <form className="">
          <div className="row">
            <div className="input-field col s6">
              <input id="username" type="text" className="validate" onChange={onUserChange}/>
              <label htmlFor="username">Username</label>
            </div>
            <div className="input-field col s6">
              <input id="password" type="password" className="validate" onChange={onPassChange}/>
              <label htmlFor="password">Passwort</label>
            </div>
          </div>
          <div className="row">
            <div className="col s6">
              <button className="waves-effect waves-light btn" onClick={onButtonClick}>Login</button>
            </div>
          </div>
        </form>
      </div>
    </Frame>
  )
}

export default Login
