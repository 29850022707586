import types from './types';
import { getStore, setStore } from '../functions/Storage'

const initialState = getStore('player', {
  id: '',
  modified: 0,
  name: '',
  level: 1,     // Allgemeine spielerbezogene Angaben
  stake: 1,
  levels: [     // Spielerbezogener Status der Levels
    {
      bank: 0,
      locked: true
    },
    {
      bank: 100,
      locked: false
    },
    {
      bank: 200,
      locked: false
    },
    {
      bank: 0,
      locked: true
    },
    {
      bank: 0,
      locked: true
    }      
  ],
  tasks: {      // Spielerbezogener Status der Aufgaben

  }
})

const reducer = (state = initialState, action) => {
  // Fehler direkt ignorieren
  if(action.payload && action.payload.error) return state
  // sonst Behandlung der Action
  switch(action.type) {
    case types.FETCH_PLAYER:
      if(state.modified < action.payload.modified) {
        setStore('player', action.payload)
        return {...state, ...action.payload}
      } else {
        return state
      }
    case types.SET_PLAYER_LEVEL:
      return {...state, level: action.payload}
    case types.UPDATE_PLAYER:
      action.payload.modified = Math.floor(Date.now() / 1000)
      setStore('player', action.payload)
      return {...state, ...action.payload}
    default:
      return state
  }
}

const fetchPlayer = () => {
  //console.log('********** fetch player')  
  return {
    type: types.FETCH_PLAYER,
    payload: '',
    meta: {
      type: 'api',
      url: '/player',
      method: 'GET'
    }
  }
}

const setLevel = level => ({
  type: types.SET_PLAYER_LEVEL,
  payload: level
})


const updatePlayer = player => {
  //console.log('********** update player', player)  
  return {
    type: types.UPDATE_PLAYER,
    payload: player,
    meta: {
      type: 'api',
      url: '/player',
      method: 'PUT'
    }
  }
}

const players = {
  initialState,
  reducer,
  fetchPlayer,
  setLevel,
  updatePlayer
}

export default players
