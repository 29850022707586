import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import levels from '../../redux/levels'
import players from '../../redux/players'
import tasks from '../../redux/tasks'
import useFirstTime from '../../hooks/useFirstTime'
import Frame from '../../containers/Frame'
import Card from './Card'

function mapStateToProps(state) {
  //console.log('map routine state', state.routines)
  return {
    levels: state.levels,
    player: state.players,
    tasks: state.tasks,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLevels: () => dispatch(levels.fetchLevels()),
    setLevel: level => dispatch(players.setLevel(level)),
    fetchTasks: level => dispatch(tasks.fetchTasks(level)),
    fetchPlayer: () => dispatch(players.fetchPlayer())
  }
}

function Dashboard(props) {
  console.log('dashboard props', props)
  const firstTime = useFirstTime()
  
  const handleClick = level => {
    console.log('level click event', level)
    props.setLevel(level)
    props.fetchTasks(level)
  }
  
  // Levels und Spielstand einmalig laden
  useEffect(() => {
    if(firstTime) {
      props.fetchLevels()
      props.fetchPlayer()
    }
  })

  return (
    <Frame>
      <div id="screen-dashboard" className="b1-grid b1-grid--4cols">
        <div className="b1-grid__item--margin">
          <Card level="1" locked={props.player.levels[1].locked} bank={props.player.levels[1].bank} clicked={handleClick}/>
        </div>
        <div className="b1-grid__item--margin">
          <Card level="2" locked={props.player.levels[2].locked} bank={props.player.levels[2].bank} clicked={handleClick}/>
        </div>
        <div className="b1-grid__item--margin">
          <Card level="3" locked={props.player.levels[3].locked} bank={props.player.levels[3].bank} clicked={handleClick}/>
        </div>
        <div className="b1-grid__item--margin">
          <Card level="4" locked={props.player.levels[4].locked} bank={props.player.levels[4].bank} clicked={handleClick}/>
        </div>
  {/*
        <div className="col s12 m3 xl2 offset-xl2">
          <Card level="0" locked={state.levels[0].locked} bank={state.levels[0].bank} clicked={handleClick}/>
        </div>
  */}
      </div>
    </Frame>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
