import React from 'react';
import { connect } from 'react-redux'
import Frame from '../containers/Frame'
import StakeJeton from '../components/StakeJeton'

function mapStateToProps(state) {
  //console.log('map routine state', state.routines);
  return {
    levels: state.levels,
    player: state.players,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //fetchLevels: () => dispatch(levels.fetchLevels()),
    //setLevel: level => dispatch(players.setLevel(level))
  }
}

function Stake(props) {
  return (
    <Frame>
      <div id="stake">
        <p>Einsatz festlegen</p>
        <div className="row">
          <div className="col s6 m3 xl2 offset-xl1">
            <StakeJeton value={props.player.stake} bank={props.player.levels[1].bank}/>
          </div>
          <div className="col s6 m3 xl2">
            <StakeJeton value={props.player.stake} bank={props.player.levels[2].bank}/>
          </div>
          <div className="col s6 m3 xl2">
            <StakeJeton value={props.player.stake} bank={props.player.levels[3].bank}/>
          </div>
          <div className="col s6 m3 xl2">
            <StakeJeton value={props.player.stake} bank={props.player.levels[4].bank}/>
          </div>
          <div className="col s6 m3 xl2">
            <StakeJeton value={props.player.stake} bank={props.player.levels[0].bank}/>
          </div>
        </div>
      </div>
    </Frame>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stake)
