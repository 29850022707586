import React from 'react'

const allowDrop = ev => {
  ev.preventDefault();
}

const EmptyCard = props => (
  <div slot={props.slot} className="b1-poker__card b1-poker__card--debossed" onDrop={props.onDrop} onDragOver={allowDrop}>
    <div className="b1-poker__card__inner">&nbsp;</div>
  </div>        
)

export default EmptyCard
