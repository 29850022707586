import React from 'react'
import UilCheck from '@iconscout/react-unicons-solid/icons/uis-check'
import { connect } from 'react-redux'

import players from '../redux/players'
import { bonbon_shower } from '../functions/Effects'

function mapStateToProps(state) {
  //console.log('map routine state', state.routines);
  return {
    state: state.players,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //fetchLevels: () => dispatch(levels.fetchLevels()),
    updatePlayer: player => dispatch(players.updatePlayer(player))
  }
}

function FinishTurnButton({ complete, answer, solutions, task, onFinish, state, updatePlayer, className }) {
  const cleanAnswer = answer.replace(/\s+/g, ' ').trim()
  console.log('current player state', state)
  //console.log('answer and solutions', answer, solutions)
  // props.complete     The Question is answered
  // props.answer       The answer string entered by the user
  // props.solutions    An array with possible solutions
  // props.onFinish(val) A function that is called, when the answer is complete and the button is clicked
  const onClick = () => {
    if(!complete) return
    let hit = null
    for(const sol of solutions) {
      const stext = sol.text.replace(/\s+/g, ' ').trim()
      if(stext === cleanAnswer) hit = sol
    }
    const win = hit ? hit.value * state.stake : -1

    const levels = [...state.levels]
    levels[state.level].bank += win
    const tasks = {...state.tasks}
    if(!tasks[task.id]) tasks[task.id] = {win: 0}
    tasks[task.id].win += Math.max(0, win)
    
    updatePlayer({...state, levels: levels, tasks: tasks})
    
    //setState({...state, levels: levels, tasks: tasks})
    if(hit) {
      // Treffer, onFinish mit der Lösung aufrufen
      bonbon_shower("#falling-bonbon")
      if(onFinish) onFinish(hit)
    } else {
      // kein Treffer, onFinish mit null aufrufen
      if(onFinish) onFinish(null)
    }
  }

  return (
    <button className={`b1-btn b1-btn--turn ${className}`} onClick={onClick}>
      <UilCheck size="100" color="white" />
    </button>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinishTurnButton)
