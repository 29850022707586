import React from 'react'

function Progress({width, className}) {
  return (
    <div className={`b1-meter ${className}`}>
      <span className="b1-meter__inner" style={{width}}></span>
    </div>
  )
}

export default Progress
