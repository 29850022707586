function bonbon_shower(bonbon_selector) {
  const bonbon = document.querySelector(bonbon_selector)
  const container = document.querySelector('body')
  const sources = [
    '/img/sweets-04.svg',
    '/img/sweets-05.svg',
    '/img/sweets-07.svg',
    '/img/sweets-08.svg'
  ]

  function createBonbon() {
    const clone = bonbon.cloneNode(true)
    const left = Math.floor(window.innerWidth * Math.random())
    clone.id = ''
    clone.src = sources[Math.floor(Math.random()*4)]
    clone.style.left = left+'px'
    clone.className = 'b1-effect__bonbon-shower b1-effect__bonbon-shower__item'
    clone.onanimationend = clone.remove
    container.append(clone)
  }

  function cleanup() {
    window.clearInterval(interval)
  }

  const interval = window.setInterval(createBonbon, 50)
  window.setTimeout(cleanup, 1500)
}

export {
  bonbon_shower
}