import React from 'react'
import { connect } from 'react-redux'
import Frame from '../../containers/Frame'
import TaskList from './TaskList'

function mapStateToProps(state) {
  //console.log('map routine state', state.routines);
  return {
    //levels: state.levels,
    player: state.players,
    tasks: state.tasks
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //fetchLevels: () => dispatch(levels.fetchLevels()),
    //setLevel: level => dispatch(players.setLevel(level))
  }
}

function Tasks({tasks, player}) {
  //const [state, setState] = useContext(TasksContext)
  //console.log('tasks state', state)
  console.log('player state', player)

  //console.log('rendering task list')
  return (
    <Frame>
      <div id="screen-tasks">
        <TaskList tasks={tasks[player.level]} player={player.tasks} />
      </div>
    </Frame>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tasks)
