import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import SideMenu from '../components/SideMenu'

function mapStateToProps(state) {
  //console.log('map routine state', state.routines);
  return {
    levels: state.levels,
    player: state.players,
    tasks: state.tasks
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //fetchLevels: () => dispatch(levels.fetchLevels()),
    //setLevel: level => dispatch(players.setLevel(level))
  }
}

function classNameByLevel(level) {
  switch(Number(level)) {
    case 1: return 'b1-color--green';
    case 2: return 'b1-color--blue';
    case 3: return 'b1-color--red';
    case 4: return 'b1-color--gold';
    default: return 'b1-color--default';
  }
}

function classNameByTask(task) {
  if(!task) return 'b1-color--bg-default';
  switch(task.type) {
    case 'roulette': return 'b1-color--bg-roulette';
    case 'poker': return 'b1-color--bg-poker';
    case 'slots': return 'b1-color--bg-slots';
    default: return 'b1-color--bg-default';
  }
}

function Frame(props) {
  const params = useParams()
  const id = params.id ? params.id : ''
  console.log('id', id)
  console.log('props tasks', props.tasks[props.player.level])
  const task = props.tasks[props.player.level].find(elem => elem.id === id )
  
  const cls = 'b1-frame '
    + classNameByLevel(props.player.level) + ' '
    + classNameByTask(task)
    
  return(
    <div className={ cls }>
      <SideMenu state={props.player} title={ task ? task.name : '' }/>
      <div className="b1-container">
        { props.children }
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Frame)
